<template>
  <div id="ad">
    <v-row class="d-flex align-center justify-space-between"
      ><v-breadcrumbs :items="items"></v-breadcrumbs>
      <v-spacer />
      <v-btn
        depressed
        outlined
        class="mr-2 custom-transform-class mt-1"
        @click.native="editAd(ad_id)"
        >Edit Ad</v-btn
      >
      <v-btn
        depressed
        outlined
        class="mr-2 custom-transform-class mt-1"
        @click.native="deleteAd(facebook_ad)"
        >Delete</v-btn
      ></v-row
    >

    <v-row class="mt-1">
      <v-col
        ><h4 v-if="facebook_ad">{{ facebook_ad.name }}</h4></v-col
      >
    </v-row>
    <v-divider></v-divider>
    <v-card v-if="facebook_ad" class="mt-2">
      <v-card-title>Ad Status</v-card-title><v-divider></v-divider>
      <v-col class="ma-1">
        <v-row justify="space-around" class="mt-2 mb-2">
          <h5 class="font-weight-medium">
            Created: {{ new Date(facebook_ad.created_time).toDateString() }}
          </h5>
          <h5 class="font-weight-medium">
            Last Updated:
            {{ new Date(facebook_ad.updated_time).toDateString() }}
          </h5>
          <h5 class="font-weight-medium">
            Status:
            {{
              facebook_ad.effective_status.toLowerCase().replaceAll("_", " ")
            }}
          </h5></v-row
        ></v-col
      >
    </v-card>

    <div v-if="facebook_creative">
      <h4 class="mt-5 mb-2">CREATIVE</h4>
      <v-divider></v-divider>
      <v-card class="mt-2 mb-2">
        <v-card-title>{{ facebook_creative.title }}</v-card-title
        ><v-divider></v-divider>
        <v-row class="mt-2 mb-2">
          <v-col class="ml-5">
            <v-img
              class="ma-2"
              :src="facebook_creative.thumbnail_url"
              height="50"
              contain
            />
            <a
              :href="facebook_creative.instagram_permalink_url"
              target="_blank"
            >
              <v-btn
                v-if="facebook_creative.instagram_permalink_url"
                class="ma-2"
                tile
                outlined
                color="success"
              >
                <v-icon left>mdi-remote-desktop</v-icon>Instagram
              </v-btn>
            </a></v-col
          ><v-divider class="mt-2 mb-2" vertical></v-divider>
          <v-col>
            <h5
              class="font-weight-medium"
              v-if="facebook_creative.call_to_action_type"
            >
              Call To Action:
              <span class="font-weight-medium">{{
                facebook_creative.call_to_action_type.replaceAll("_", " ")
              }}</span>
            </h5>
            <h5 class="font-weight-medium" v-if="facebook_creative.body">
              Content:
              <span class="font-weight-light">{{
                facebook_creative.body.replaceAll("_", " ")
              }}</span>
            </h5>
          </v-col></v-row
        >
      </v-card>
    </div>

    <h4 class="mt-5 mb-2">Ad Stats</h4>
    <v-divider></v-divider>
    <v-expand-x-transition>
      <v-col v-if="facebook_ad_insights">
        <v-row>
          <v-col lg="3" md="6" cols="12">
            <v-card outlined>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="display-1">
                    {{
                      facebook_ad_insights.impressions
                        ? facebook_ad_insights.impressions.toLocaleString("en")
                        : 0
                    }}
                  </v-card-title>
                  <v-card-subtitle class="overline grey--text lighten-5">
                    Impressions
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col lg="3" md="6" cols="12">
            <v-card outlined>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="display-1">
                    ${{
                      facebook_ad_insights.spend
                        ? facebook_ad_insights.spend.toLocaleString("en")
                        : 0
                    }}
                  </v-card-title>
                  <v-card-subtitle class="overline grey--text lighten-5">
                    Spend
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col lg="3" md="6" cols="12">
            <v-card outlined>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="display-1">
                    {{
                      facebook_ad_insights.reach
                        ? facebook_ad_insights.reach.toLocaleString("en")
                        : 0
                    }}
                  </v-card-title>
                  <v-card-subtitle class="overline grey--text lighten-5">
                    Reach
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col lg="3" md="6" cols="12">
            <v-card outlined>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="display-1">
                    {{
                      facebook_ad_insights.clicks
                        ? facebook_ad_insights.clicks.toLocaleString("en")
                        : 0
                    }}
                  </v-card-title>
                  <v-card-subtitle class="overline grey--text lighten-5">
                    Clicks
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="3" md="6" cols="12">
            <v-card outlined>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="display-1">
                    {{
                      facebook_ad_insights.ctr
                        ? parseFloat(facebook_ad_insights.ctr).toFixed(3)
                        : 0
                    }}%
                  </v-card-title>
                  <v-card-subtitle class="overline grey--text lighten-5">
                    CTR
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col lg="3" md="6" cols="12">
            <v-card outlined>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="display-1">
                    ${{
                      facebook_ad_insights.cpc
                        ? parseFloat(facebook_ad_insights.cpc).toFixed(3)
                        : 0
                    }}
                  </v-card-title>
                  <v-card-subtitle class="overline grey--text lighten-5">
                    CPC
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col lg="3" md="6" cols="12">
            <v-card outlined>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="display-1">
                    ${{
                      facebook_ad_insights.cpm
                        ? parseFloat(facebook_ad_insights.cpm).toFixed(3)
                        : 0
                    }}
                  </v-card-title>
                  <v-card-subtitle class="overline grey--text lighten-5">
                    CPM
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col lg="3" md="6" cols="12">
            <v-card outlined>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="display-1">
                    ${{
                      facebook_ad_insights.cpp
                        ? parseFloat(facebook_ad_insights.cpp).toFixed(3)
                        : 0
                    }}
                  </v-card-title>
                  <v-card-subtitle class="overline grey--text lighten-5">
                    CPP
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-expand-x-transition>
  </div>
</template>

<script>
// import FsLightbox from "fslightbox-vue";

export default {
  data: () => ({
    impressions_card_title: "Impressions",
    spend_card_title: "Spend",
    spend_currency: "$",
    reach_card_title: "Reach",
    clicks_card_title: "Clicks",
    ctr_card_title: "CTR",
    average_cpc_card_title: "Average CPC",
    average_cpm_card_title: "Average CPM",
    average_cpp_card_title: "Average CPP",
    percentage: "%",
    items: [
      {
        text: "Facebook Ads",
        disabled: false,
        href: "#/facebook/ads",
      },
      {
        text: "Single Ad",
        disabled: true,
        href: "#",
      },
    ],
    toggler: false,
    adAccountId: "",
    date_preset: "maximum",
    multi_images: [],
    time_increment: 1,
    headers: [
      {
        text: "Thumbnail",
        value: "thumbnail_url",
        width: "256px",
        align: "start",
      },
      { text: "Status", value: "status", width: "200px" },
    ],
  }),
  async mounted() {
    this.ad_id = this.$route.params.id;
    this.adAccountId = sessionStorage.getItem("adAccountId");

    // Get Ad Details
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("facebook/getFacebookAd", {
        adAccountId: this.adAccountId,
        ad_id: this.ad_id,
      });
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error
      this.$store.commit("makingApiRequest", false);
    }

    //Get Ad Stats
    try {
      await this.$store.dispatch("facebook/getFacebookAdInsights", {
        adAccountId: this.adAccountId,
        ad_id: this.ad_id,
      });
    } catch (error) {
      this.error = error
    }

    //Get Ad Creatives
    try {
      await this.$store.dispatch("facebook/getAdCreatives", {
        adAccountId: this.adAccountId,
        ad_id: this.ad_id,
      });
    } catch (error) {
      this.error = error
    }

    //Get Ad Creative
    try {
      await this.$store.dispatch("facebook/getFacebookCreative", {
        adAccountId: this.adAccountId,
        creativeId: this.facebook_ad.creative.id,
      });
    } catch (error) {
      this.error = error
    }

    //Get Ad Image
    try {
      await this.$store.dispatch("facebook/getAdImage", {
        adAccountId: this.adAccountId,
        hashes: [this.facebook_creative.asset_feed_spec.images[0].hash],
      });
    } catch (error) {
      this.error = error
    }

    //Get Ad Video
    try {
      await this.$store.dispatch("facebook/getAdVideo", {
        adAccountId: this.adAccountId,
        video_id: this.facebook_creative.object_story_spec.video_data.video_id,
      });
    } catch (error) {
      this.error = error
    }

    //Get Carousel Ad Images
    try {
      const hashes =
        this.facebook_creative.object_story_spec.link_data.child_attachments.map(
          (image_hash) => image_hash.image_hash
        );
      await this.$store.dispatch("facebook/getAdImage", {
        adAccountId: this.adAccountId,
        hashes,
      });
    } catch (error) {
      this.error = error
    }

    //Display Carousel Ad
    try {
      this.multi_images = this.carousel_images.map((url) => url.url);
    } catch (error) {
      this.error = error
    }
  },
  components: {
    // FsLightbox,
  },
  methods: {
    editAd(ad_id) {
      this.$router.push({
        name: "edit-facebook-ad",
        params: {
          id: ad_id,
        },
      });
    },
    async deleteAd(facebook_ad) {
      const ids = {
        adAccountId: facebook_ad.account_id,
        ad_id: facebook_ad.id,
      };
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("facebook/deleteFacebookAd", ids);
      this.$store.commit("makingApiRequest", false);
      this.$notify({
        title: "Ad successfully deleted!",
        group: "success",
      });
      this.$router.push(`/facebook/ads`);
    },
  },
  computed: {
    facebook_ad() {
      return this.$store.state.facebook.facebook_ad;
    },
    facebook_ad_creatives() {
      return this.$store.state.facebook.facebook_ad_creatives;
    },
    facebook_creative() {
      return this.$store.state.facebook.facebook_creative;
    },
    ad_image() {
      return this.$store.state.facebook.ad_image;
    },
    facebook_ad_insights() {
      return this.$store.state.facebook.facebook_ad_insights;
    },
    carousel_images() {
      return this.$store.state.facebook.ad_image;
    },
    ad_video() {
      return this.$store.state.facebook.ad_video;
    },
  },
};
</script>

<style>
#ad .v-image__image--contain {
  background-position-x: left !important;
}
</style>